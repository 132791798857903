import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import "./Embla.css"
import img1 from "../assets/photos/card1.jpeg";
import img2 from "../assets/photos/card2.jpeg";
import img3 from "../assets/photos/card3.jpeg";
import img4 from "../assets/photos/card4.jpeg";
import img5 from "../assets/photos/card5.jpeg";

const EmblaCarousel = (props) => {
  const { options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({ stopOnInteraction: true, playOnInit: true })
  ]) 

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi)

  const cards = [
    { id: 1, imgSrc: img1 },
    { id: 2, imgSrc: img2 },
    { id: 3, imgSrc: img3 },
    { id: 4, imgSrc: img4 },
    { id: 5, imgSrc: img5 }
  ];

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    emblaApi.on('pointerDown', () => autoScroll.stop()) // Stop autoplay when the user interacts.
  }, [emblaApi])

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {cards.map((card) => (
            <div className="embla__slide" key={card.id}
              style={{
                backgroundImage: `url(${card.imgSrc})`, // Set the background image
                backgroundSize: "cover", // Ensures the image covers the entire card
                backgroundPosition: "center", // Centers the image
                backgroundRepeat: "no-repeat" // Prevents tiling of the image
              }}>
              <div className="embla__slide__number"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '')}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default EmblaCarousel