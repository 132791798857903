import React, { useState } from 'react';
import styled from 'styled-components';
import { FaHome, FaShoppingBasket } from 'react-icons/fa';
import { FiCompass } from 'react-icons/fi';
import { IoCall } from 'react-icons/io5';
import { BiNews } from "react-icons/bi";
import { FaBook } from "react-icons/fa6";
import './Navbar.css';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1c1b22;
  padding: 10px;
  border-radius: 25px;
  width: 90%;
  max-width: 500px;
  margin: 20px auto;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? '#FEB509' : '#666')};
  font-size: 12px;
  cursor: pointer;
  position: relative;
  padding: 10px;
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 24px;
    margin-right: ${props => (props.active ? '10px' : '0')};
  }

  &:hover {
    color: #FEB509;
  }
`;

const Label = styled.div`
  display: ${props => (props.active ? 'inline-flex' : 'none')};
  background-color: #FEB509;
  color: #1c1b22;
  border-radius: 25px;
  padding: 10px 15px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
  align-items: center;
`;

const Navbar = ({ homeRef, secondPageRef, aboutRef, featuresRef, contactRef }) => {
  const [active, setActive] = useState('home');

  const handleClick = (item, ref) => {
    setActive(item);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='navHolder'> 
      <NavbarContainer>

      <NavItem active={active === 'home'} onClick={() => handleClick('home', homeRef)}>
        <FaHome />
        {active === 'home' && <Label active={active === 'home'}>Home</Label>}
      </NavItem>

      <NavItem active={active === 'about'} onClick={() => handleClick('about', aboutRef)}>
      <FaBook />
        {active === 'about' && <Label active={active === 'about'}>About</Label>}
      </NavItem>

      <NavItem active={active === 'secondPage'} onClick={() => handleClick('secondPage', secondPageRef)}>
        <FiCompass />
        {active === 'secondPage' && <Label active={active === 'secondPage'}>Explore</Label>}
      </NavItem>
      <NavItem active={active === 'features'} onClick={() => handleClick('features', featuresRef)}>
        <BiNews />
        {active === 'features' && <Label active={active === 'features'}>Features</Label>}
      </NavItem>
      <NavItem active={active === 'contact'} onClick={() => handleClick('contact', contactRef)}>
        <IoCall />
        {active === 'contact' && <Label active={active === 'contact'}>Contact</Label>}
      </NavItem>
    </NavbarContainer>
    </div>
    
  );
};

export default Navbar;