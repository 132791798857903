import React from 'react'
import "./Contact.css"
import ContactForm from '../components/ContactForm'
function Contact() {
  return (
    <div className='ContactPage'>
        <ContactForm/>
    </div>
  )
}

export default Contact

