import React from 'react'
import EmblaCarousel from '../components/EmblaCarousel'
import "./Features.css"
const OPTIONS = { loop: true ,  slidesToScroll: 'auto'}
const SLIDE_COUNT = 5
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

function Features() {
  const heading=[{title:"200K+", content:"Impressions"},
                  {title:"7X", content: "Brand Recall"},
                  {title:"12X",content:"Effective" },
                  {title:"75%", content:"Cost Reduction"}];
  return (
    <div className='FeaturesPage'>
        <div className="Features">
          <h1>Maximise Reach, Minimise Efforts, Cut Costs</h1>
          <div className='featuresInnerBox'>
          {heading.map((head)=>(
            <div className='FeaturesBox'>
              {console.log(head)}
              <h2>{head.title}</h2> 
              <h3>{head.content}</h3>
            </div>
          ))}
          </div>
        </div> 
        <EmblaCarousel slides={SLIDES} options={OPTIONS}/>
    </div>
  )
}

export default Features

