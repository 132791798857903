// import { useState, useEffect, React } from 'react';
// import "./Home.css";
// import VideoSrc from "../assets/videos/homevideo.mp4"; 
// import VideoSrcPhome from "../assets/videos/homevideophone.mp4";
// import { MdKeyboardDoubleArrowDown } from "react-icons/md";
// import SecondPage from './SecondPage';

// function Home() {
//     const [videoSrc, setVideoSrc] = useState(VideoSrc);

//     useEffect(() => {
//         const handleResize = () => {
//             if (window.innerWidth < 400) {
//                 setVideoSrc(VideoSrcPhome);
//             } else {
//                 setVideoSrc(VideoSrc);
//             }
//         };

//         handleResize(); // Ensure the correct video is selected initially
//         window.addEventListener("resize", handleResize);

//         return () => {
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);

//     const [showDiv, setShowDiv] = useState(false);

//     useEffect(() => {
//         const timeoutId = setTimeout(() => {
//             setShowDiv(true);
//         }, 10000); // 10 seconds delay

//         return () => clearTimeout(timeoutId);
//     }, []);

//     const scrollToNextSection = () => {
//         const nextSection = document.getElementById('secondPage'); // Target the next section
//         if (nextSection) {
//             nextSection.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the section
//         }
//     };
//     const scrollDown = () => {
//       scrollDown100vh()
//     };

//     function scrollDown100vh() {
//       // Scroll down by 100% of the current viewport height
//       window.scrollBy({
//         top: window.innerHeight,  // 100vh equivalent
//         left: 0,                  // No horizontal scrolling
//         behavior: 'smooth'        // Smooth scrolling
//       });
//     }

//     return (
//         <div className="homepage">
//             {/* Video background */}
//             <video
//                 key={videoSrc} // This ensures that the video component re-renders when the video source changes
//                 autoPlay
//                 muted
//                 className="videoBackground"
//                 preload="metadata"
//             >
//                 <source src={videoSrc} type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//             {showDiv && (
//                 <div className="scroll">
//                     <MdKeyboardDoubleArrowDown
//                         style={{ height: '50px', width: '60px', color: "#FEB509" }}
//                         onClick={scrollDown}
//                     />
//                     <h1><a href="#secondPage"></a>Scroll <br /> Down</h1>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default Home;


import { useState, useEffect } from 'react';
import "./Home.css";
import VideoSrc from "../assets/videos/homevideo.mp4"; 
import VideoSrcPhome from "../assets/videos/homevideophone.mp4";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import SecondPage from './SecondPage';

function Home() {
    const [videoSrc, setVideoSrc] = useState(VideoSrc);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 400) {
                setVideoSrc(VideoSrcPhome);
            } else {
                setVideoSrc(VideoSrc);
            }
        };

        handleResize(); // Ensure the correct video is selected initially
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [showDiv, setShowDiv] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowDiv(true);
        }, 10000); // 10 seconds delay

        return () => clearTimeout(timeoutId);
    }, []);

    const scrollDown100vh = () => {
        // Scroll down by 100% of the current viewport height
        window.scrollBy({
            top: window.innerHeight,  // 100vh equivalent
            left: 0,                  // No horizontal scrolling
            behavior: 'smooth'        // Smooth scrolling
        });
    };

    return (
        <div className="homepage">
            {/* Video background */}
            <video
                key={videoSrc} // This ensures that the video component re-renders when the video source changes
                autoPlay
                muted
                className="videoBackground"
                preload="metadata"
            >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {showDiv && (
                <div className="scroll">
                    <MdKeyboardDoubleArrowDown
                        style={{ height: '50px', width: '60px', color: "#FEB509" }}
                        onClick={scrollDown100vh}
                    />
                    <h1>Scroll <br /> Down</h1>
                </div>
            )}
        </div>
    );
}

export default Home;