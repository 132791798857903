
import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { IoIosCall, IoIosMail } from "react-icons/io";
// import { MdArrowOutward } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { PiLinkedinLogoBold } from "react-icons/pi";
import './Footer.css';

function Footer() {
  return (
    <footer>
  <div class="footer-container">
    <div class="footer-left">
      <h3>DISPLYFY</h3>
      <h4>Attentions Drives Results</h4>
    </div>
    {/* <div class="footer-middle">
      <a href="#">About</a>
      <a href="#">Features</a>
      <a href="#">Works</a>
      <a href="#">Support</a>
    </div> */}
    <div class="footer-right">
      <a href="https://www.instagram.com/displyfy/?igsh=MXR6bWlzdGl1Zms4aQ%3D%3D"><FaInstagram/></a>
      <a href="https://www.linkedin.com/company/displyfy/?viewAsMember=true"><PiLinkedinLogoBold/></a>
    </div>
  </div>
  <div class="footer-bottom">
    <p>© Copyright 2024, All Rights Reserved</p>
  </div>
</footer>
  );
}

export default Footer;