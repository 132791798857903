import {React, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { BsMouse } from "react-icons/bs";
import "./About.css"
import { MdArrowOutward } from "react-icons/md";

function About() {

  const words=["Accessible", "Affordable", "Simplified", "Effective"]
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [fade, setFade] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setFade(false);
      }, 500); // Duration of the fade-out effect
    }, 2000); // Change word every second

    return () => clearInterval(interval); 
  }, []);


  return (
    <div className='aboutPage'>

        <div className='rightDiv'>
          <div>
          <h1 className='aboutH1'> ELEVATE YOUR DOOH GAME</h1>
          <p>Displyfy, your premier destination for <b>Innovative</b> and <b>Cost-Effective</b> advertising solutions in Bangalore. 
          Our mission is to revolutionize the way businesses reach their <b>Audience</b> by leveraging the power of strategically placed digital displays in high footfall areas.</p>
          </div>

          <div className='quickCommerce'>
            <h1>Quick Commerce for Small Businesses & Products</h1>
            <p>We're excited to introduce our upcoming quick commerce platform designed to empower small businesses and product owners. Stay tuned for more updates!</p>
            <h3>Coming Soon 🚀</h3>
            <p>If you're interested in partnering with us, reach out and be the first to get onboard with us</p>
            
            <Link to="/contact">
            <button class="button-64" role="button"><span class="text">Contact Us <MdArrowOutward style={{"fontSize":""}}/> </span></button>
            </Link>
          </div>

        </div>

        <div className="sideDiv">
            <p className='title1'>Make Advertising  </p><span className={`sentence ${fade ? 'fade' : ''}`}>{words[currentWordIndex]}. </span>
        </div>
    </div>
  )
}

export default About