import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';

const ContactForm = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    firstName: '',
    companyName: '',
    email: '',
    phone: '',
    message: '',
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Prevent multiple clicks
  const [successMessage, setSuccessMessage] = useState(''); // Success message

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'First Name is required';
    if (!formData.companyName) newErrors.companyName = 'Company Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage('');
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0 && !isSubmitting) {
      setIsSubmitting(true); // Disable button to prevent multiple clicks
      emailjs
        .sendForm('displyfy-website', 'template_sybpchf', form.current, 'SWt7o2Ak5PO1tj-IQ')
        .then(
          () => {
            setSuccessMessage('Your message has been successfully sent! Our team will contact you soon.');
            setFormData({
              firstName: '',
              companyName: '',
              email: '',
              phone: '',
              message: '',
              agree: false,
            });
            setIsSubmitting(false); // Re-enable the button after success
          },
          (error) => {
            console.log('FAILED...', error.text);
            setIsSubmitting(false); // Re-enable the button after failure
          }
        );
    } else {
      setErrors(formErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  return (
    <div className="contact-container">
      <div className="info-section">
        <h2>Love to hear from you, Get in touch 👋</h2>
        <p>Our friendly team is here to help. <br />connect@displyfy.com</p>
        <p>
          <strong>Call us</strong> <br />
          +91 9916050428 <br />
          +91 9019770219
        </p>
        <div className="social-media">
          <i className="fab fa-facebook"></i>
          <i className="fab fa-linkedin"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-twitter"></i>
        </div>
      </div>

      <form className="form-section" ref={form} onSubmit={handleSubmit}>
        {successMessage && <p className="success-message">{successMessage}</p>} {/* Success message */}
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          className={errors.firstName ? 'input-error' : ''}
        />
        {errors.firstName && <p className="error-message">{errors.firstName}</p>} {/* Show error if validation fails */}
        
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          placeholder="Company Name"
          className={errors.companyName ? 'input-error' : ''}
        />
        {errors.companyName && <p className="error-message">{errors.companyName}</p>} {/* Show error if validation fails */}

        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className={errors.email ? 'input-error' : ''}
        />
        {errors.email && <p className="error-message">{errors.email}</p>} {/* Show error if validation fails */}

        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number"
          className={errors.phone ? 'input-error' : ''}
        />
        {errors.phone && <p className="error-message">{errors.phone}</p>} {/* Show error if validation fails */}

        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          className={errors.message ? 'input-error' : ''}
        ></textarea>
        {errors.message && <p className="error-message">{errors.message}</p>} {/* Show error if validation fails */}

        <div className="checkbox-group">
          <input
            type="checkbox"
            name="agree"
            checked={formData.agree}
            onChange={handleChange}
          />
          <span>
            I’d like to receive more information about the company. I understand and agree to the <a href="#privacy-policy">Privacy Policy</a>.
          </span>
        </div>

        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;