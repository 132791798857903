import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import SecondPage from './pages/SecondPage';
import Contact from './pages/Contact';
import Features from './pages/Features';
import Footer from './components/Footer';
import { useRef } from 'react';

function App() {
  // Create refs for each section
  const homeRef = useRef(null);
  const secondPageRef = useRef(null);
  const aboutRef = useRef(null);
  const featuresRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <Router>
      <div>
        {/* Pass the refs to the respective sections */}
        <div ref={homeRef}>
          <Home />
        </div>
        <div ref={aboutRef} id='secondPage'>
          <SecondPage />
        </div>
        <div ref={secondPageRef}>
          <About />
        </div>
        <div ref={featuresRef}>
          <Features />
        </div>
        <div ref={contactRef}>
          <Contact />
        </div>
        {/* Pass the refs to the Navbar */}
        <Navbar
          homeRef={homeRef}
          secondPageRef={secondPageRef}
          aboutRef={aboutRef}
          featuresRef={featuresRef}
          contactRef={contactRef}
        />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
