import React from 'react'
import "./SecondPage.css"
function SecondPage() {
  return (
    <div className='SecondPage' id='secondPage'>
        <div className='textDiv'>
            <h1>About Displyfy</h1>
            <p>At Displyfy, we are redefining the way brands connect with people. By turning everyday digital screens into captivating advertising platforms, we spark real engagement. With a passion for innovation, our mission is to make advertising exciting, interactive, and deeply engaging.  Welcome to Displyfy, where reach meets value.</p>
        </div>
        <div className='imageDiv'>
          <div className='image'> </div>
        </div>
    </div>
  )
}

export default SecondPage